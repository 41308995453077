import React, { FC } from 'react';

import { Disclaimer } from '@components/Disclaimer';
import * as S from './FooterDisclaimer.styled';

type Props = {
  className?: string;
};

export const FooterDisclaimer: FC<Props> = (props) => {
  return (
    <S.Container className={props.className}>
      <Disclaimer />
    </S.Container>
  );
};
