import { entries } from 'lodash';

export type ValidationResponse = string;

const validateField = (name: string, value: any, validators: { [key: string]: (val: any) => ValidationResponse }) => {
  const validator = validators[name];

  if (validator) {
    const error = validator(value);

    if (error) {
      return error;
    }
  }

  return '';
};

export const getFormValidator = <T extends Record<string, any>>(formValidator: {
  [key in keyof Partial<T>]: (val: T[keyof T]) => ValidationResponse;
}) => {
  return (data: T): { [key in keyof Partial<T>]: ValidationResponse } => {
    const errors = {} as { [key in keyof Partial<T>]: ValidationResponse };

    entries(data).forEach(([name, value]) => {
      const error = validateField(name, value, formValidator);

      if (error) {
        errors[name as keyof T] = error;
      }
    });

    return errors;
  };
};
