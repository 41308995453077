import { getFullCountryName, getStateIdByFullName } from '@utils/location';

type Params = {
  city?: string;
  state?: string;
  country?: string;
};
export const mapLocationToString = (params: Params) => {
  const locationParts = [
    params.city || null,
    params.state ? getStateIdByFullName(params.state) : null,
    params.country ? getFullCountryName(params.country) : null,
  ].filter(Boolean);

  if (locationParts.length === 0) return undefined;

  return locationParts.join(', ');
};
