import { useCallback, useEffect, RefObject, useMemo } from 'react';

export const useOutsideEvent = <T extends HTMLElement>(
  targetRef: RefObject<T>,
  event: 'click' | 'touchStart',
  cb: VoidFunction,
) => {
  const checkIfTargetElementOutside = useCallback(
    (node: EventTarget | null) => {
      if (!node) {
        return false;
      }
      return !!targetRef.current && !targetRef.current.contains(node as Node);
    },
    [targetRef],
  );

  const listener = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (checkIfTargetElementOutside(e.target) && cb) {
        void cb();
      }
    },
    [checkIfTargetElementOutside, cb],
  );

  const mappedEvent = useMemo(() => {
    switch (event) {
      case 'touchStart':
        return 'touchstart';
    }

    return event;
  }, [event]);

  useEffect(() => {
    document.addEventListener(mappedEvent, listener);

    return () => {
      document.removeEventListener(mappedEvent, listener);
    };
  }, [listener, mappedEvent]);
};
