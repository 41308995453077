import GoogleAnalytics from 'react-ga';

import * as analytics from '~/constants/analytics';
import * as application from '~/constants/application';

export const init = () => {
  GoogleAnalytics.initialize(analytics.TRACKING_ID, { debug: application.isDev });
};

export const page = () => {
  GoogleAnalytics.pageview(window.location.pathname + window.location.search);
};
