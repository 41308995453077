export const truncateForFullSentence = (str: string, lenght = 500, separator = '.') => {
  if (!str) {
    return '';
  }

  if (str.length <= lenght) {
    return str;
  }

  const indexOfSeparator = str.indexOf(separator, lenght);

  if (indexOfSeparator < 0) {
    return str;
  }

  return str.slice(0, indexOfSeparator + 1);
};
