import moment from 'moment';
import Highcharts from 'highcharts';
import { IsoDate } from '@models/common';
import { formatMoney } from '@utils/money';

export const performanceGraphOptions = (
  data: Array<[IsoDate, number]>,
  graphType: 'portfolio' | 'account',
  fillColor: string,
  lineColor: string,
  height?: number,
): Highcharts.Options => {
  return {
    chart: {
      // minPadding: 0,
      // maxPadding: 0,
      marginBottom: 3,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      height: height || 260,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      minPadding: 0,
      maxPadding: 0,
      lineWidth: 0,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      tickWidth: 0,
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      lineWidth: 0,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      minPadding: 0,
      maxPadding: 0,
      tickWidth: 0,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      area: {
        turboThreshold: 3000,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, fillColor],
            [1, 'var(--eko-secondary-color)'],
          ],
        },
        marker: {
          radius: 2,
          fillColor: lineColor,
          states: {
            hover: {
              fillColor: lineColor,
            },
          },
        },
        lineWidth: 3,
        lineColor: lineColor,
        states: {
          hover: {
            lineWidth: 3,
          },
        },
        threshold: null,
        clip: false,
      },
    },
    tooltip: {
      // outside: true,
      backgroundColor: 'transparent',
      borderWidth: 0,
      padding: 20,
      shadow: false,
      shape: 'square',
      useHTML: true,
      borderRadius: 0,
      // boxShadow: "0px 9px 44px -6px rgba(161, 166, 192, 0.16)",
      // fontWeight: 400,
      // color: "#171b25",
      formatter: function () {
        if (graphType === 'portfolio') {
          const value = data[this.point.index][1].toFixed(2);

          return (
            '<span style="color: var(--eko-third-color); position: relative; z-index: 1; font-size: 16px;' +
            'line-height: 20px; font-family: EkoTitle, Philosopher, Arial, sans-serif; font-weight: 600;">' +
            this.key +
            '</span><br/>' +
            '<span style="display: flex; align-items: center; padding-top: 10px; position: relative;">' +
            '<span style="position: relative; z-index: 1; font-size: 14px; line-height: 14px; font-family: EkoTitle,' +
            'Philosopher, Arial, sans-serif; font-weight: 600; color: var(--eko-third-color);">' +
            (Number(value) >= 0 ? '+' : '') +
            formatMoney(value) +
            '</span>' +
            '<span style="position: absolute; left: -15px; bottom: -20px; width: calc(100% + 30px);' +
            'height: 86px; background: white; box-shadow: 0 9px 44px -6px rgba(161, 166, 192, 0.16);' +
            'border-radius: 20px;"></span> </span>'
          );
        } else {
          const value = data[this.point.index][1].toFixed(2);
          return (
            '<span style="color: var(--eko-third-color); position: relative; z-index: 1; font-size: 16px;' +
            'line-height: 20px; font-family: EkoTitle, Philosopher, Arial, sans-serif; font-weight: 600;">' +
            this.key +
            '</span><br/>' +
            '<span style="display: flex; align-items: center; padding-top: 10px; position: relative;">' +
            '<span style="position: relative; z-index: 1; font-size: 14px; line-height: 14px; font-family: EkoTitle,' +
            'Philosopher, Arial, sans-serif; font-weight: 600; color: var(--eko-third-color);">' +
            formatMoney(value) +
            '</span>' +
            '<span style="position: absolute; left: -15px; bottom: -20px; width: calc(100% + 30px);' +
            'height: 86px; background: white;' +
            'box-shadow: 0 9px 44px -6px rgba(161, 166, 192, 0.16); border-radius: 20px;">' +
            '</span>' +
            '</span>'
          );
        }
      },
    },
    series: [
      {
        type: 'area',
        data: data,
      },
    ],
    responsive: {
      rules: [
        {
          condition: { maxWidth: 650 },
          chartOptions: {
            plotOptions: {
              area: {
                marker: {
                  radius: 1,
                },
                lineWidth: 2,
                states: {
                  hover: {
                    lineWidth: 2,
                  },
                },
              },
            },
          },
        },
      ],
    },
  };
};

export const individualPerformanceGraphOptions = (
  data: Array<[string, number]>,
  fillColor: string,
  lineColor: string,
  graphRanges: { maxRangeValue: number; minRangeValue: number } | null,
  height = 200,
): Highcharts.Options => {
  return {
    chart: {
      // minPadding: 0,
      // maxPadding: 0,
      marginBottom: 3,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      height,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      minPadding: 0,
      maxPadding: 0,
      lineWidth: 0,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      tickWidth: 0,
    },
    yAxis: {
      min: graphRanges && graphRanges.minRangeValue,
      max: graphRanges && graphRanges.maxRangeValue,
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      lineWidth: 0,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      minPadding: 0,
      maxPadding: 0,
      tickWidth: 0,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, fillColor],
            [1, 'var(--eko-secondary-color)'],
          ],
        },
        marker: {
          radius: 2,
          fillColor: lineColor,
          states: {
            hover: {
              fillColor: lineColor,
            },
          },
        },
        lineWidth: 3,
        lineColor: lineColor,
        states: {
          hover: {
            lineWidth: 3,
          },
        },
        threshold: null,
      },
    },
    responsive: {
      rules: [
        {
          condition: { maxWidth: 650 },
          chartOptions: {
            plotOptions: {
              area: {
                marker: {
                  radius: 1,
                },
                lineWidth: 2,
                states: {
                  hover: {
                    lineWidth: 2,
                  },
                },
              },
            },
          },
        },
      ],
    },
    tooltip: {
      backgroundColor: 'transparent',
      borderWidth: 0,
      padding: 20,
      shadow: false,
      shape: 'square',
      useHTML: true,
      borderRadius: 0,
      // boxShadow: '0px 9px 44px -6px rgba(161, 166, 192, 0.16)',
      // fontWeight: 400,
      // color: '#171b25',
      formatter: function () {
        const value = data[this.point.index][1].toFixed(2);
        return (
          '<span style="color: var(--eko-third-color); position: relative; z-index: 1; font-size: 16px;' +
          'line-height: 20px; font-family: EkoTitle, Philosopher, Arial, sans-serif; font-weight: 600;">' +
          this.key +
          '</span><br/>' +
          '<span style="display: flex; align-items: center; padding-top: 10px; position: relative;">' +
          '<span style="position: relative; z-index: 1; font-size: 14px; line-height: 14px; font-family: EkoTitle,' +
          'Philosopher, Arial, sans-serif; color: var(--eko-third-color); font-weight: 600;">$' +
          value +
          '</span>' +
          '<span style="position: absolute; left: -15px; bottom: -20px; width: calc(100% + 30px); height: 86px;' +
          'background: white; box-shadow: 0 9px 44px -6px rgba(161, 166, 192, 0.16); border-radius: 20px;">' +
          '</span>' +
          '</span>'
        );
      },
    },
    series: [
      {
        type: 'area',
        data: data,
      },
    ],
  };
};
export const getYearGraphStartDate = () => {
  const startDate = moment().subtract(1, 'year');
  return new Date(startDate.year(), startDate.month(), startDate.date()).toISOString();
};

/* @deprecated */
export const getMonthGraphStartDate = () => {
  const startDate = moment().subtract(1, 'month');
  return new Date(startDate.year(), startDate.month(), startDate.date()).toISOString();
};

export const getEndDate = () => {
  return moment().subtract(15, 'minutes').subtract(10, 'seconds').toISOString();
};

export const getWeekStartDate = () => {
  const date = moment().subtract(1, 'week');
  return new Date(date.year(), date.month(), date.date()).toISOString();
};

export const getMaxStartDate = () => {
  const date = moment().subtract(10, 'year');
  return new Date(date.year(), date.month(), date.date()).toISOString();
};

export const individualPerformanceGraphDurations = {
  MONTH: 'MONTH',
  YEAR: 'YEAR',
  WEEK: 'WEEK',
  MAX: 'MAX',
} as const;

export type IndividualPerformanceGraphDurationsKeys = keyof typeof individualPerformanceGraphDurations;

export type IndividualPerformanceGraphDurations =
  (typeof individualPerformanceGraphDurations)[IndividualPerformanceGraphDurationsKeys];
