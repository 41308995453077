import { useCallback, useEffect } from 'react';

export enum GlobalEvent {
  HIDE_ALL_POPUPS = 'HIDE_ALL_POPUPS',
  HIDE_SIDE_PAGE = 'HIDE_SIDE_PAGE',
}

export const useReactOnGlobalEvent = (event: GlobalEvent, cb: VoidFunction) => {
  useEffect(() => {
    window.document.addEventListener(event, cb);

    return () => {
      window.document.removeEventListener(event, cb);
    };
  }, [event, cb]);
};

export const useDispatchGlobalEvent = () => {
  return useCallback((e: GlobalEvent) => {
    window.document.dispatchEvent(new Event(e));
  }, []);
};
