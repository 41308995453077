import moment from 'moment-timezone';

export const fundingSources = [
  {
    name: 'Employment income',
    value: 'employment_income',
  },
  {
    name: 'Investments',
    value: 'investments',
  },
  {
    name: 'Inheritance',
    value: 'inheritance',
  },
  {
    name: 'Business income',
    value: 'business_income',
  },
  {
    name: 'Savings',
    value: 'savings',
  },
  {
    name: 'Family',
    value: 'family',
  },
] as const;

export const USCitizenType = {
  yes: 'yes',
  greenCard: 'greenCard',
  onVisa: 'onVisa',
  no: 'no',
};

export const isUSCitizenOptions = [
  {
    name: 'Yes',
    value: USCitizenType.yes,
  },
  {
    name: 'I have a Green Card',
    value: USCitizenType.greenCard,
  },
  {
    name: 'I’m here on a visa',
    value: USCitizenType.onVisa,
  },
  {
    name: 'No',
    value: USCitizenType.no,
  },
] as const;

export const visaTypes = [
  {
    name: 'B1',
    value: 'B1',
  },
  {
    name: 'B2',
    value: 'B2',
  },
  {
    name: 'DACA',
    value: 'DACA',
  },
  {
    name: 'E1',
    value: 'E1',
  },
  {
    name: 'E2',
    value: 'E2',
  },
  {
    name: 'E3',
    value: 'E3',
  },
  {
    name: 'F1',
    value: 'F1',
  },
  {
    name: 'G4',
    value: 'G4',
  },
  {
    name: 'H1B',
    value: 'H1B',
  },
  {
    name: 'J1',
    value: 'J1',
  },
  {
    name: 'L1',
    value: 'L1',
  },
  {
    name: 'O1',
    value: 'O1',
  },
  {
    name: 'TN1',
    value: 'TN1',
  },
  {
    name: 'Other',
    value: 'OTHER',
  },
];

export const incomeOptions = [
  { name: '< $20,000', value: '< $20,000' },
  { name: '$20,000 - $49,999', value: '$20,000 - $49,999' },
  { name: '$50,000 - $99,999', value: '$50,000 - $99,999' },
  { name: '$100,000 - $499,999', value: '$100,000 - $499,999' },
  { name: '> $500,000', value: '> $500,000' },
] as const;

export type IncomeOption = (typeof incomeOptions)[number]['name'];

export const employmentValues = {
  employed: 'employed',
  unemployed: 'unemployed',
  student: 'student',
  retired: 'retired',
};

export const employmentOptions = [
  { name: 'Employed', value: employmentValues.employed },
  { name: 'Unemployed', value: employmentValues.unemployed },
  { name: 'Student', value: employmentValues.student },
  { name: 'Retired', value: employmentValues.retired },
] as const;

export enum slideNames {
  'LegalName' = 'LegalName',
  'DateOfBirth' = 'DateOfBirth',
  'IsUSTaxResident' = 'IsUSTaxResident',
  'IsUSCitizen' = 'IsUSCitizen',
  'ContactInfo' = 'ContactInfo',
  'FinancialSituation' = 'FinancialSituation',
  'EmploymentStatus' = 'EmploymentStatus',
  'Disclosures' = 'Disclosures',
  'PoliticallyExposedPersonIdentity' = 'PoliticallyExposedPersonIdentity',
  'EmployerInfo' = 'EmployerInfo',
  'SSN' = 'SSN',
  'CountryOfCitizenshipAndBirth' = 'CountryOfCitizenshipAndBirth',
  'DateOfDeparture' = 'DateOfDeparture',
  'VisaDetails' = 'VisaDetails',
  'Confirmation' = 'Confirmation',
}

export const getDateFromTimestampByNYTimezone = (timeStamp: string | number, format: string) => {
  return moment(timeStamp).tz('America/New_York').format(format);
};

export const getDateFromTimestamp = (timeStamp: string | number, format: string) => {
  return moment(timeStamp).tz('UTC').format(format);
};
