import { Eq } from 'fp-ts/Eq';

export const checkById =
  (targetId: string | number) =>
  <T extends { id: string | number }>(checkalue: T) =>
    checkalue.id === targetId;

export const getEqById = <T extends { id: string | number }>(): Eq<T> => ({
  equals: (a, b) => a.id === b.id,
});
